import { useState, useEffect, useMemo, useRef } from "react";
import { Axios } from "../config";
import { getAuthToken } from '../utils/auth_token.utils'
import { isObjectsEqual } from "../utils/object-values.util";
import { removeEditedItem } from "../redux/list/list.actions";
import { useDispatch } from "react-redux";
import { removeDeletedItems } from "../redux/delete/delete.actions";

const useFetchReport = (
    endpoint,
    authTokenFromState,
    setSkip,
    skip,
    limit,
    reportDateRange,
    isRefresh,
    reportSideBarNavActiveItem,
    hasNoDate,
    setLoadedData,
    loadedData,
    loadedTotalNumItems,
    filterInput,
    paymentMethod,
    paymentStatus,
    locationFilter,
    expenseType,
    territory,
    productOwner
    // setIsFirstLoad,
    // isFirstLoad
) => {
    const [data, setData] = useState([]);
    const [dataExport, setDataExport] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [numItems, setNumItems] = useState(0);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadedData, setIsLoadedData] = useState(false);

    const prevReportDateRangeRef = useRef({});
    const prevFilterInputRef = useRef('');
    const prevpaymentMethodRef = useRef({});
    const prevexpenseTypeRef = useRef({});
    const prevterritoryRef = useRef({});
    const prevproductOwnerRef = useRef({});
    const prevpaymentStatusRef = useRef({});
    const prevlocationFilterRef = useRef({});
    const prevIsRefreshRef = useRef(false);
    const prevSkiphRef = useRef(0);
    const prevreportSideBarNavActiveItemRef = useRef(null);

    const dispatch = useDispatch();

    const tokenHeader = getAuthToken(authTokenFromState);
    const fetchData = async (isRefresh) => {
        try {
            const preFilterInput = prevFilterInputRef.current;
            const prevpaymentMethod = prevpaymentMethodRef.current;
            const prevexpenseType = prevexpenseTypeRef.current;
            const prevterritory = prevterritoryRef.current;
            const prevproductOwner = prevproductOwnerRef.current;
            const prevpaymentStatus = prevpaymentStatusRef.current;
            const prevlocationFilter = prevlocationFilterRef.current;
            const prevIsRfresh = prevIsRefreshRef.current;
            const preReportDateRange = prevReportDateRangeRef.current

            const skipRest =
                filterInput !== '' && preFilterInput != filterInput ||
                    (paymentMethod && !(isObjectsEqual(prevpaymentMethod, paymentMethod))) ||
                    (expenseType && !(isObjectsEqual(prevexpenseType, expenseType))) ||
                    (territory && !(isObjectsEqual(prevterritory, territory))) ||
                    (productOwner && !(isObjectsEqual(prevproductOwner, productOwner))) ||
                    (paymentStatus && !(isObjectsEqual(prevpaymentStatus, paymentStatus))) ||
                    (locationFilter && !(isObjectsEqual(prevlocationFilter, locationFilter))) ||
                    (reportDateRange && !(isObjectsEqual(preReportDateRange, reportDateRange))) ?
                    setSkip(0) :
                    null

            if (isRefresh) {
                setSkip(0);
                prevIsRefreshRef.current = isRefresh;
                return null
            }

            const response = await Axios.get(endpoint, {
                headers: tokenHeader,
                params: {
                    skip:
                        filterInput !== '' && preFilterInput != filterInput ||
                            (paymentMethod && !(isObjectsEqual(prevpaymentMethod, paymentMethod))) ||
                            (expenseType && !(isObjectsEqual(prevexpenseType, expenseType))) ||
                            (territory && !(isObjectsEqual(prevterritory, territory))) ||
                            (productOwner && !(isObjectsEqual(prevproductOwner, productOwner))) ||
                            (paymentStatus && !(isObjectsEqual(prevpaymentStatus, paymentStatus))) ||
                            (locationFilter && !(isObjectsEqual(prevlocationFilter, locationFilter))) ||
                            (reportDateRange && !(isObjectsEqual(preReportDateRange, reportDateRange))) ?
                            0 :
                            skip,

                    limit,
                    // filterInput: filterInput,
                    filterInput: preFilterInput != filterInput ?
                        filterInput :
                        skip !== 0 ?
                            filterInput :
                            prevIsRfresh ?
                                filterInput :
                                null,
                    reportDateRange,
                    paymentMethodValue: paymentMethod ? paymentMethod.value : null,
                    expenseTypeValue: expenseType ? expenseType.value : null,
                    territory: territory && territory.length ? JSON.stringify(territory) : null,
                    productOwnerValue: productOwner ? productOwner.value : null,
                    paymentStatusLabel: paymentStatus ? paymentStatus.label : null,
                    locationFilterValue: locationFilter ? locationFilter.value : null,
                    isFirstLoad: prevIsRefreshRef.current === true ||
                        (filterInput !== '' && preFilterInput != filterInput ||
                            (paymentMethod && !(isObjectsEqual(prevpaymentMethod, paymentMethod))) ||
                            (expenseType && !(isObjectsEqual(prevexpenseType, expenseType))) ||
                            (territory && !(isObjectsEqual(prevterritory, territory))) ||
                            (productOwner && !(isObjectsEqual(prevproductOwner, productOwner))) ||
                            (paymentStatus && !(isObjectsEqual(prevpaymentStatus, paymentStatus))) ||
                            (locationFilter && !(isObjectsEqual(prevlocationFilter, locationFilter))) ||
                            (reportDateRange && !(isObjectsEqual(preReportDateRange, reportDateRange)))) ||
                        prevreportSideBarNavActiveItemRef.current !== reportSideBarNavActiveItem ?
                        true :
                        false,
                }
            })

            if ((preFilterInput && preFilterInput !== filterInput) ||
                (skip === 0) ||
                (isRefresh === true)
            ) {
                setData(response.data.dataSlice);
            }
            else {
                setData(prevData => [...prevData, ...response.data.dataSlice]);
            }

            if (prevIsRefreshRef.current === true ||
                (filterInput !== '' && preFilterInput != filterInput ||
                    (paymentMethod && !(isObjectsEqual(prevpaymentMethod, paymentMethod))) ||
                    (expenseType && !(isObjectsEqual(prevexpenseType, expenseType))) ||
                    (territory && !(isObjectsEqual(prevterritory, territory))) ||
                    (productOwner && !(isObjectsEqual(prevproductOwner, productOwner))) ||
                    (paymentStatus && !(isObjectsEqual(prevpaymentStatus, paymentStatus))) ||
                    (locationFilter && !(isObjectsEqual(prevlocationFilter, locationFilter))) ||
                    (reportDateRange && !(isObjectsEqual(preReportDateRange, reportDateRange)))) ||
                prevreportSideBarNavActiveItemRef.current !== reportSideBarNavActiveItem) {
                setDataExport(response.data.dataExport);
                setSummaryData(response.data.summary)
                setTotalData(response.data.total)
            }

            setNumItems(response.data.numItems);
            setError(null);
            setIsLoading(false);

            prevlocationFilterRef.current = locationFilter;
            prevpaymentMethodRef.current = paymentMethod;
            prevexpenseTypeRef.current = expenseType;
            prevterritoryRef.current = expenseType;
            prevproductOwnerRef.current = expenseType;
            prevpaymentStatusRef.current = paymentStatus;
            prevReportDateRangeRef.current = reportDateRange;
            prevFilterInputRef.current = filterInput;
            prevSkiphRef.current = skip
            prevreportSideBarNavActiveItemRef.current = reportSideBarNavActiveItem

            if (prevIsRefreshRef.current === true) {
                prevIsRefreshRef.current = false
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400
                    || error.response.status === 401
                    || error.response.status === 404
                ) {
                    setError({
                        name: error.response.data.name,
                        message: error.response.data.message,
                        status: error.response.status
                    })
                } else {
                    setError({
                        name: "system error",
                        message: "Please, try again after few moments. If you still can’t get the result you are looking for, contact your administrator."
                    })
                }
            }
            else if (error.request) {
                setError({
                    name: "network error",
                    message: "Please, make sure you are connected to the network. If you still can’t get the result you are looking for, contact your administrator."
                })
            }
            else {
                setError({
                    name: "unknown error",
                    message: "An unknown error occurred, please contact your administrator."
                })
            }

            setDataExport([]);
            setSummaryData([])
            setTotalData([])
            setData([]);
            setIsLoading(false);
            prevlocationFilterRef.current = locationFilter;
            prevpaymentMethodRef.current = paymentMethod;
            prevexpenseTypeRef.current = expenseType;
            prevterritoryRef.current = territory;
            prevproductOwnerRef.current= productOwner;
            prevpaymentStatusRef.current = paymentStatus;
            prevReportDateRangeRef.current = reportDateRange;
            prevFilterInputRef.current = filterInput;
            prevreportSideBarNavActiveItemRef.current = reportSideBarNavActiveItem
        }
    }

    useEffect(() => {
        console.log("running featch report")
    })

    useEffect(() => {
        setDataExport([]);
        setSummaryData([])
        setTotalData([])
        setData([]);
        setNumItems(0);
        prevlocationFilterRef.current = {};
        prevpaymentMethodRef.current = {};
        prevexpenseTypeRef.current = {};
        prevterritoryRef.current = {};
        prevproductOwnerRef.current = {};
        prevpaymentStatusRef.current = {};
        prevReportDateRangeRef.current = {};
        // prevFilterInputRef.current = '';
        prevIsRefreshRef.current = false;
        prevSkiphRef.current = 0;
    }, [reportSideBarNavActiveItem])

    useEffect(() => {
        if ((Object.keys(reportDateRange).length || hasNoDate) || filterInput || paymentMethod || expenseType || territory || productOwner || paymentStatus || locationFilter) {
            setIsLoading(true)
        }
    }, [reportDateRange, isRefresh, reportSideBarNavActiveItem, filterInput, paymentMethod, expenseType || territory || productOwner || paymentStatus, locationFilter])

    useEffect(() => {
      
        if ((Object.keys(reportDateRange).length || hasNoDate) || filterInput || paymentMethod || expenseType || territory || productOwner || paymentStatus || locationFilter) {
            if (loadedData && (loadedData ? loadedData.length !== 0 : false)) {
                setData(loadedData)
                setLoadedData([])
                setIsLoading(false);
                setDataExport(loadedData);
                setNumItems(loadedTotalNumItems);
                setError(null);
                setIsLoadedData(true);
                prevFilterInputRef.current = filterInput;
                prevlocationFilterRef.current = locationFilter;
                prevpaymentStatusRef.current = paymentStatus;
                prevpaymentMethodRef.current = paymentMethod;
                prevexpenseTypeRef.current = expenseType;
                prevterritoryRef.current = territory;
                prevproductOwnerRef.current = productOwner;
                prevReportDateRangeRef.current = reportDateRange;
            }
            else {
                fetchData();
            }
        }
    }, [skip, reportDateRange, isRefresh, reportSideBarNavActiveItem, filterInput, paymentMethod, expenseType, territory, productOwner, paymentStatus, locationFilter]);

    return {
        data,
        dataExport,
        summaryData,
        totalData,
        numItems,
        error,
        isLoading
    };
}

export default useFetchReport;