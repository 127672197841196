import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { setIsItemHistory, setLoadedData, setLocationFilter, setPaymentMethod, setPaymentStatus, setReportColumn, setReportDateRange, setReportInfo, setReportSideBarNav, setReportSideBarNavActiveItem, setReportType } from "../../redux/report/report.actions";
import { selectReportSideBarNavActiveItem, selectReportType } from "../../redux/report/report.reselect";
import { setTabTitle } from '../../utils/tab-title.utils';

import { INVENTORY_REPORT_REORDER_BY_ITEM_INFO, INVENTORY_REPORT_REORDER_BY_ITEM_COLUMN_TEMPLATE } from './inventory-report-reorder.template';
import { INVENTORY_REPORT_BY_QUANTITY_INFO, INVENTORY_REPORT_BY_QUANTITY_COLUMN_TEMPLATE } from './inventory-report-qty.template';
import { INVENTORY_REPORT_BY_CATEGORY_INFO, INVENTORY_REPORT_BY_CATEGORY_COLUMN_TEMPLATE } from './inventory-report-category.template';
import { INVENTORY_REPORT_VALUATION_INFO, INVENTORY_REPORT_VALUATION_COLUMN_TEMPLATE } from './inventory-report-valuation.template';
import { PRODUCTION_REPORT_BY_ORDER_INFO, PRODUCTION_REPORT_BY_ORDER_COLUMN_TEMPLATE } from './production-report.template';
import { EXPIRATION_REPORT_BY_ORDER_INFO, EXPIRATION_REPORT_BY_ORDER_COLUMN_TEMPLATE } from './expiration-report.template';
import { STOCK_TRANSFER_REPORT_BY_ORDER_INFO, STOCK_TRANSFER_REPORT_BY_ORDER_COLUMN_TEMPLATE } from './stock-transfer-report.template';
import { STOCK_COUNT_REPORT_BY_ORDER_INFO, STOCK_COUNT_REPORT_BY_ORDER_COLUMN_TEMPLATE } from './stock-count-report.template';

import Report from "../../components/report/report.component";
import { setFilterEmpty } from "../../redux/filter/filter.actions";

const TYPE = {
    INVENTORY_REPORT: "INVENTORY_REPORT",
}

const SUB_ITEMS = [
    {
        text: "Stock By Quantity",
        info: INVENTORY_REPORT_BY_QUANTITY_INFO,
        reportColumn: INVENTORY_REPORT_BY_QUANTITY_COLUMN_TEMPLATE
    },
    {
        text: "Stock Reorder",
        info: INVENTORY_REPORT_REORDER_BY_ITEM_INFO,
        reportColumn: INVENTORY_REPORT_REORDER_BY_ITEM_COLUMN_TEMPLATE
    },
    {
        text: "Stock By Category",
        info: INVENTORY_REPORT_BY_CATEGORY_INFO,
        reportColumn: INVENTORY_REPORT_BY_CATEGORY_COLUMN_TEMPLATE
    },
    {
        text: "Stock Valuation",
        info: INVENTORY_REPORT_VALUATION_INFO,
        reportColumn: INVENTORY_REPORT_VALUATION_COLUMN_TEMPLATE
    },
    {
        text: "Stock Transfer",
        info: STOCK_TRANSFER_REPORT_BY_ORDER_INFO,
        reportColumn: STOCK_TRANSFER_REPORT_BY_ORDER_COLUMN_TEMPLATE
    },
    {
        text: "Stock Count",
        info: STOCK_COUNT_REPORT_BY_ORDER_INFO,
        reportColumn: STOCK_COUNT_REPORT_BY_ORDER_COLUMN_TEMPLATE
    },
    {
        text: "Product Expiration",
        info: EXPIRATION_REPORT_BY_ORDER_INFO,
        reportColumn: EXPIRATION_REPORT_BY_ORDER_COLUMN_TEMPLATE
    },
    {
        text: "Manufacturing",
        info: PRODUCTION_REPORT_BY_ORDER_INFO,
        reportColumn: PRODUCTION_REPORT_BY_ORDER_COLUMN_TEMPLATE
    },
]

const dataKeys = []

const InventoryReport = ({
    reportType,
    setReportType,
    setReportColumn,
    setReportSideBarNav,
    setReportSideBarNavActiveItem,
    reportSideBarNavActiveItem,
    setReportInfo,
    setReportDateRange,
    setLoadedData,
    setPaymentMethod,
    setPaymentStatus,
    setLocationFilter,
    setFilterEmpty,
    setIsItemHistory,
    match
}) => {
    const [confrimLoadedData, setConfirmLoadedData] = useState(false);
    const [isFristRender, setIsFristRender] = useState(true);
    const endpoint = reportType === TYPE.INVENTORY_REPORT ? `${SUB_ITEMS[reportSideBarNavActiveItem].info.path}${SUB_ITEMS[reportSideBarNavActiveItem].info.subPath}` :
        `${SUB_ITEMS[0].info.path}${SUB_ITEMS[0].info.subPath}`

    useEffect(() => {
        if (reportType === TYPE.INVENTORY_REPORT) {
            setReportInfo(SUB_ITEMS[reportSideBarNavActiveItem].info);
            setReportSideBarNav(SUB_ITEMS);
        } else {
            setReportInfo(SUB_ITEMS[0].info);
            setReportSideBarNav(SUB_ITEMS);
        }
    }, [reportSideBarNavActiveItem]);

    useEffect(() => {
        // setReportDateRange({})
        setIsFristRender(false);
    }, [])

    useEffect(() => {
        setTabTitle(`Stock Report`)
    }, [])

    useEffect(() => {
        setIsItemHistory(false)
    }, [])

    useEffect(() => {
        if (reportType !== TYPE.INVENTORY_REPORT) {
            setLoadedData({
                data: null,
                skip: null,
                totalNumItems: 0
            })

            setLocationFilter(null);
            setPaymentMethod(null);
            setPaymentStatus(null);
            setReportDateRange({})
            setReportSideBarNavActiveItem(0)
            setFilterEmpty();
        }

        setReportType(TYPE.INVENTORY_REPORT);
        setConfirmLoadedData(true);
    }, [])

    useEffect(() => {
        return () => {
            setReportInfo(null);
        }
    }, [])

    return (
        <>
            {!isFristRender && confrimLoadedData &&
                <Report
                    reportColumn={SUB_ITEMS[reportSideBarNavActiveItem].reportColumn}
                    endpoint={endpoint}
                    dataKeys={dataKeys}
                />
            }
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    reportSideBarNavActiveItem: selectReportSideBarNavActiveItem,
    reportType: selectReportType
})

const mapDispatchToProps = (disptach) => ({
    setReportInfo: (reportInfo) =>
        disptach(setReportInfo(reportInfo)),
    setReportColumn: (data) =>
        disptach(setReportColumn(data)),
    setReportSideBarNav: (data) =>
        disptach(setReportSideBarNav(data)),
    setReportSideBarNavActiveItem: (data) =>
        disptach(setReportSideBarNavActiveItem(data)),
    setReportDateRange: (data) =>
        disptach(setReportDateRange(data)),
    setReportType: (data) =>
        disptach(setReportType(data)),
    setLoadedData: (data) =>
        disptach(setLoadedData(data)),
    setPaymentMethod: (data) =>
        disptach(setPaymentMethod(data)),
    setPaymentStatus: (data) =>
        disptach(setPaymentStatus(data)),
    setLocationFilter: (data) =>
        disptach(setLocationFilter(data)),
    setFilterEmpty: (data) =>
        disptach(setFilterEmpty(data)),
    setIsItemHistory: (data) =>
        disptach(setIsItemHistory(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InventoryReport));