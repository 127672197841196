import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';
import { formatNumberToTwoDecimal } from '../../utils/format-number.util';

export const INVENTORY_REPORT_BY_QUANTITY_INFO = {
  reportTitle: "Stock Reports",
  path: "/inventoryreports",
  subPath: "/inventorybyqty",
  listPage: "/inventory-reports",
  pagePath:"items",
  hasChart: false,
  tableHasGrandTotal: true,
  hasLocationFilter: true,
  hasSearch: true
}

export const INVENTORY_REPORT_BY_QUANTITY_COLUMN_TEMPLATE = [
  {
    Header: "Product Code/Name",
    accessor: "itemCodeName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Total'
  },
  {
    Header: "Category",
    accessor: "category",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Branch",
    accessor: "locationCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Qty on Hand",
    accessor: "qtyOnHand",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      if (!value) {
          return displayCellValue(value);
      }

      value.label = value && value.label && isNaN(value.label) ?
          value.label :
          !isMobileView ? formatNumberToTwoDecimal(value.label) : `${formatNumberToTwoDecimal(value.label)} Quantity`


      return (
          <div className={isMobileView && isRightAligned ?
              'list-table__body__row__cell--right-aligned-mobile-cell' : ''}>
              <TableCellStatus
                  value={value}
                  noDot={true}
              />
          </div>
      );
    }
  },
  {
    Header: "Recovered",
    accessor: "recovered",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Reserve: ${value ? displayCellValueNumberToTwoDecimal(value) : 0}`;
      }

      return value ? displayCellValueNumberToTwoDecimal(value) : 0;
    },
    columnId: "work_order"
  },
  {
    Header: "Reserve",
    accessor: "reserve",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Reserve: ${value ? displayCellValueNumberToTwoDecimal(value) : 0}`;
      }

      return value ? displayCellValueNumberToTwoDecimal(value) : 0;
    }
  },
  {
    Header: "Available",
    accessor: "available",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Available: ${value ? displayCellValueNumberToTwoDecimal(value) : 0}`;
      }

      return value ? displayCellValueNumberToTwoDecimal(value) : 0;
    }
  },
  {
    Header: "Potential Revenue (ETB)",
    accessor: "potentialRevenue",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Total Value: ${value ? displayCellValueNumberToTwoDecimal(value) : 0}`;
      }

      return value ? displayCellValueNumberToTwoDecimal(value) : 0;
    }, 
    Footer: 'potentialRevenue'
  },
  
]
